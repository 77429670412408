var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/volunteer.ts
var FormatVolunteer = class {
  static {
    __name(this, "FormatVolunteer");
  }
  constructor() {
  }
  static computeAssignmentDuration(assignments) {
    return assignments.reduce(
      (acc, assignment) => acc + assignment.duration.inMilliseconds,
      0
    );
  }
};

// src/assign-volunteer-to-task/assign-volunteer-to-task.ts
var AssignVolunteerToTask = class {
  constructor(allVolunteers) {
    this.allVolunteers = allVolunteers;
  }
  static {
    __name(this, "AssignVolunteerToTask");
  }
  async volunteers() {
    const volunteers = await this.allVolunteers.findAll();
    return volunteers.map(
      (assignee) => this.computeAssignmentDuration(assignee)
    );
  }
  computeAssignmentDuration({
    assignments,
    ...volunteer
  }) {
    const assignmentDuration = FormatVolunteer.computeAssignmentDuration(assignments);
    return {
      ...volunteer,
      hasAtLeastOneFriend: false,
      assignmentDuration
    };
  }
};

// src/assign-task-to-volunteer/assignment.ts
function isTeamMember(assignee) {
  return Object.hasOwn(assignee, "as");
}
__name(isTeamMember, "isTeamMember");
function isMemberOf(team) {
  return (assignee) => {
    if (!isTeamMember(assignee)) return false;
    return assignee.as === team;
  };
}
__name(isMemberOf, "isMemberOf");
function isWithDetails(assignment) {
  return Object.hasOwn(assignment, "appointment");
}
__name(isWithDetails, "isWithDetails");

// src/assign-task-to-volunteer/assign-task-to-volunteer.ts
import { Period } from "@overbookd/time";

// src/count-assignees-in-team.ts
function countAssigneesInTeam(team, assignees) {
  return assignees.filter(isMemberOf(team)).length;
}
__name(countAssigneesInTeam, "countAssigneesInTeam");

// src/assign-task-to-volunteer/assign-task-to-volunteer.ts
var AssignTaskToVolunteer = class {
  constructor(allTasks, assignableVolunteers) {
    this.allTasks = allTasks;
    this.assignableVolunteers = assignableVolunteers;
  }
  static {
    __name(this, "AssignTaskToVolunteer");
  }
  async tasks(all = false) {
    const tasks = await this.allTasks.findAll();
    const withMissingTeams = tasks.map(
      (task) => this.computeMissingAssignmentTeams(task)
    );
    return withMissingTeams.filter((task) => all || task.teams.length > 0).map(({ teams, ...task }) => ({
      ...task,
      teams: removeDuplicates(teams)
    }));
  }
  async selectTask(taskId) {
    const task = await this.allTasks.findOne(taskId);
    return this.computeAssignmentsSummary(task);
  }
  async selectAssignment(assignmentIdentifier) {
    const task = await this.allTasks.findOne(assignmentIdentifier.taskId);
    const assignment = task.assignments.find(
      ({ assignmentId, mobilizationId }) => assignmentId === assignmentIdentifier.assignmentId && mobilizationId === assignmentIdentifier.mobilizationId
    );
    if (!assignment) throw new Error("Assignment not found");
    const assignmentSpecification = {
      period: Period.init(assignment),
      oneOfTheTeams: this.filterMissingTeamMembers(assignment)
    };
    const volunteers = await this.assignableVolunteers.on(
      assignmentIdentifier,
      assignmentSpecification
    );
    return volunteers.map(({ assignments, requestedDuring, ...volunteer }) => {
      const totalAssignmentDuration = FormatVolunteer.computeAssignmentDuration(
        assignments.map((period) => Period.init(period))
      );
      const assignmentDuration = FormatVolunteer.computeAssignmentDuration(
        assignments.filter(({ category }) => category === task.category).map((period) => Period.init(period))
      );
      const isRequestedOnSamePeriod = requestedDuring.some(
        (period) => period.isOverlapping(Period.init(assignment))
      );
      return {
        ...volunteer,
        totalAssignmentDuration,
        assignmentDuration,
        isRequestedOnSamePeriod
      };
    });
  }
  computeMissingAssignmentTeams(task) {
    const missingTeamMembers = task.assignments.reduce(
      (teams, assignment) => {
        const missingTeamMembers2 = this.filterMissingTeamMembers(assignment);
        return [...teams, ...missingTeamMembers2];
      },
      []
    );
    return {
      id: task.id,
      name: task.name,
      topPriority: task.topPriority,
      category: task.category,
      inChargeTeam: task.inChargeTeam,
      teams: Array.from(new Set(missingTeamMembers))
    };
  }
  filterMissingTeamMembers({
    demands,
    assignees
  }) {
    return demands.filter(({ team, demand: demands2 }) => {
      const countAssignees = countAssigneesInTeam(team, assignees);
      return countAssignees < demands2;
    }).map(({ team }) => team);
  }
  computeAssignmentsSummary(task) {
    const assignments = task.assignments.map((assignment) => {
      const period = Period.init(assignment);
      const { taskId, mobilizationId, assignmentId } = assignment;
      return {
        start: period.start,
        end: period.end,
        taskId,
        mobilizationId,
        assignmentId,
        teams: assignment.demands.map(({ team, demand }) => ({
          team,
          demand,
          assigned: countAssigneesInTeam(team, assignment.assignees)
        }))
      };
    });
    return {
      id: task.id,
      name: task.name,
      category: task.category,
      assignments
    };
  }
};
function removeDuplicates(teams) {
  return Array.from(new Set(teams));
}
__name(removeDuplicates, "removeDuplicates");

// src/assignment.error.ts
var AssignmentError = class extends Error {
  static {
    __name(this, "AssignmentError");
  }
};
var TaskNotFoundError = class extends AssignmentError {
  static {
    __name(this, "TaskNotFoundError");
  }
  constructor(taskId) {
    super(`La FT #${taskId} est introuvable`);
  }
};

// src/teams.ts
var HARD = "hard";
var VIEUX = "vieux";
var CONFIANCE = "confiance";

// src/assign-task-to-volunteer/funnel/candidate.ts
var Candidate = class _Candidate {
  constructor(candidate) {
    this.candidate = candidate;
  }
  static {
    __name(this, "Candidate");
  }
  static init({ volunteer, friends }, { planning, availabilities, breakPeriods }, assignment) {
    const assignableTeams = _Candidate.getAssignableTeams(
      assignment,
      volunteer.teams
    );
    const as = assignableTeams.length === 1 ? assignableTeams.at(0) : void 0;
    return new _Candidate({
      ...volunteer,
      friends,
      planning,
      availabilities,
      as,
      assignableTeams,
      breakPeriods
    });
  }
  static from(candidate) {
    return new _Candidate(candidate);
  }
  static getAssignableTeams({ demands, assignees }, teams) {
    const remainingDemands = demands.reduce(
      (remainingDemands2, { team, demand }) => {
        const totalAssignees = assignees.filter(isMemberOf(team)).length;
        if (totalAssignees === demand) return remainingDemands2;
        return [...remainingDemands2, { team, demand: demand - totalAssignees }];
      },
      []
    );
    return assignableTeamsAccordingToRemainingDemands(teams, remainingDemands);
  }
  static toAssignment({ id, as }) {
    return { id, as };
  }
  demandAs(as) {
    if (!this.candidate.assignableTeams.includes(as)) {
      throw new Error(`${this.name} is not team member of ${as}`);
    }
    return new _Candidate({ ...this.candidate, as });
  }
  assignableTeamsAccordingTo(remainingDemands) {
    const { teams } = this.candidate;
    return assignableTeamsAccordingToRemainingDemands(teams, remainingDemands);
  }
  get name() {
    return `${this.candidate.firstname} ${this.candidate.lastname}`;
  }
  get json() {
    return this.candidate;
  }
};
function assignableTeamsAccordingToRemainingDemands(teams, remainingDemands) {
  const implicitTeams = retrieveImplicitTeams(teams);
  return implicitTeams.filter(
    (team) => remainingDemands.map(({ team: team2 }) => team2).includes(team)
  );
}
__name(assignableTeamsAccordingToRemainingDemands, "assignableTeamsAccordingToRemainingDemands");
function retrieveImplicitTeams(teams) {
  const areConfianceByDefault = [HARD, VIEUX];
  const isConfiance = teams.some(
    (team) => areConfianceByDefault.includes(team)
  );
  return isConfiance ? [...teams, CONFIANCE] : teams;
}
__name(retrieveImplicitTeams, "retrieveImplicitTeams");
function isFulfillingDemand(candidate) {
  return candidate.as !== void 0;
}
__name(isFulfillingDemand, "isFulfillingDemand");
var CandidateFactory = class {
  constructor(agendas, friends) {
    this.agendas = agendas;
    this.friends = friends;
  }
  static {
    __name(this, "CandidateFactory");
  }
  async from(volunteer, assignment) {
    const { start, end } = assignment;
    const [planning, availabilities, breakPeriods, friends] = await Promise.all(
      [
        this.agendas.planning.for(volunteer.id),
        this.agendas.availabilities.for(volunteer.id),
        this.agendas.breakPeriods.for(volunteer.id),
        this.friends.availableDuringWith({ start, end }, volunteer.id)
      ]
    );
    const agenda = { planning, availabilities, breakPeriods };
    const relationShip = { volunteer, friends };
    return Candidate.init(relationShip, agenda, assignment);
  }
};

// src/assign-task-to-volunteer/funnel/funnel.ts
var FunnelError = class extends Error {
  static {
    __name(this, "FunnelError");
  }
};

// src/assign-task-to-volunteer/funnel/startup-funnel.ts
var InactiveFunnel = class {
  static {
    __name(this, "InactiveFunnel");
  }
  candidates = [];
  canAssign = false;
  assign() {
    return Promise.reject(
      new FunnelError("Impossible d'affecter pour le moment")
    );
  }
  canFulfillMoreRemainingDemands = false;
  addCandidate() {
    return Promise.resolve(this);
  }
  fulfillDemand() {
    return this;
  }
  canRevokeLastCandidate = false;
  revokeLastCandidate() {
    return this;
  }
  canChangeLastCandidate = false;
  previousCandidate() {
    return Promise.resolve(this);
  }
  nextCandidate() {
    return Promise.resolve(this);
  }
};
var ReadyToStart = class _ReadyToStart extends InactiveFunnel {
  constructor(candidateFactory, assignments) {
    super();
    this.candidateFactory = candidateFactory;
    this.assignments = assignments;
  }
  static {
    __name(this, "ReadyToStart");
  }
  static init(candidateFactory, assignments) {
    return new _ReadyToStart(candidateFactory, assignments);
  }
  select(assignment) {
    return WaitingForVolunteer.init(
      this.candidateFactory,
      this.assignments,
      assignment
    );
  }
};
var WaitingForVolunteer = class _WaitingForVolunteer extends InactiveFunnel {
  constructor(candidateFactory, assignments, assignment) {
    super();
    this.candidateFactory = candidateFactory;
    this.assignments = assignments;
    this.assignment = assignment;
  }
  static {
    __name(this, "WaitingForVolunteer");
  }
  static init(candidateFactory, assignments, assignment) {
    return new _WaitingForVolunteer(candidateFactory, assignments, assignment);
  }
  async select(volunteer) {
    const candidate = await this.candidateFactory.from(
      volunteer,
      this.assignment
    );
    const repositories = {
      assignments: this.assignments,
      candidateFactory: this.candidateFactory
    };
    return AssignVolunteerFunnel.init([
      [candidate],
      repositories,
      this.assignment
    ]);
  }
};

// src/assign-task-to-volunteer/funnel/funnel-shared-logic.ts
var CommonFunnel = class {
  constructor(_candidates, repositories, assignment) {
    this._candidates = _candidates;
    this.repositories = repositories;
    this.assignment = assignment;
  }
  static {
    __name(this, "CommonFunnel");
  }
  get candidates() {
    return this._candidates.map(({ json }) => json);
  }
  get canAssign() {
    return areEveryCandidateFulfillingDemand(this._candidates);
  }
  get canFulfillMoreRemainingDemands() {
    const candidates = this._candidates;
    const canFulfillAllDemands = this.canTheyFulfillAllDemands(candidates);
    if (canFulfillAllDemands) return false;
    return this.hasAssignableFriends(candidates);
  }
  canTheyFulfillAllDemands(candidates) {
    const remainingDemands = this.remainingDemandsIfAssignThose(candidates);
    const candidatesWithoutSelectedTeam = candidates.filter(
      (candidate) => !isFulfillingDemand(candidate.json)
    );
    const totalRemainingDemands = remainingDemands.reduce(
      (total, { demand }) => total + demand,
      0
    );
    const totalAssignableCandidates = candidatesWithoutSelectedTeam.length;
    return totalAssignableCandidates === totalRemainingDemands;
  }
  remainingDemandsIfAssignThose(candidates) {
    const { assignees, demands } = this.assignment;
    const remainingDemands = demands.reduce(
      (remainingDemands2, { team, demand }) => {
        const alreadyAssigned = assignees.filter(isMemberOf(team)).length;
        const temporarlyAssigned = candidates.filter(
          (candidate) => isMemberOf(team)(candidate.json)
        ).length;
        const totalAssignees = alreadyAssigned + temporarlyAssigned;
        if (totalAssignees === demand) return remainingDemands2;
        return [...remainingDemands2, { team, demand: demand - totalAssignees }];
      },
      []
    );
    return remainingDemands;
  }
  get friendsAbleToFulfillNextDemand() {
    return this.assignableFriendsFrom(this._candidates);
  }
  get friendsAssignableOnLastDemand() {
    return this.assignableFriendsFrom(this.otherCandidatesThanTheLastOne);
  }
  hasAssignableFriends(candidates) {
    const assignableFriends = this.assignableFriendsFrom(candidates);
    const hasAssignableFriends = assignableFriends.length > 0;
    return hasAssignableFriends;
  }
  assignableFriendsFrom(candidates) {
    const remainingDemands = this.remainingDemandsIfAssignThose(candidates);
    const assignment = { ...this.assignment, demands: remainingDemands };
    return candidates.flatMap((candidate) => candidate.json.friends).reduce((friends, friend) => {
      const isNotACandidateYet = candidates.every(
        ({ json }) => json.id !== friend.id
      );
      const isAssignable = isAssignableOn(assignment, friend);
      const isNotAlreadyListed = friends.every(({ id }) => id !== friend.id);
      const shouldBeAdded = isNotACandidateYet && isAssignable && isNotAlreadyListed;
      if (!shouldBeAdded) return friends;
      return [...friends, friend];
    }, []);
  }
  get canRevokeLastCandidate() {
    return this._candidates.length > 1;
  }
  get otherCandidatesThanTheLastOne() {
    return this._candidates.slice(0, -1);
  }
  get canChangeLastCandidate() {
    const selectableFriends = this.assignableFriendsFrom(
      this.otherCandidatesThanTheLastOne
    );
    return selectableFriends.length > 1;
  }
};
function isAssignableOn(assignment, volunteer) {
  return Candidate.getAssignableTeams(assignment, volunteer.teams).length > 0;
}
__name(isAssignableOn, "isAssignableOn");
function areEveryCandidateFulfillingDemand(candidates) {
  return candidates.every((candidate) => isFulfillingDemand(candidate.json));
}
__name(areEveryCandidateFulfillingDemand, "areEveryCandidateFulfillingDemand");

// src/assign-task-to-volunteer/funnel/assign-volunteer-funnel.ts
var AssignVolunteerFunnel = class _AssignVolunteerFunnel extends CommonFunnel {
  static {
    __name(this, "AssignVolunteerFunnel");
  }
  static init(initializer) {
    const [candidates, repositories, assignment] = initializer;
    return new _AssignVolunteerFunnel(candidates, repositories, assignment);
  }
  async assign() {
    if (!areEveryCandidateFulfillingDemand(this._candidates)) {
      return Promise.reject(
        new FunnelError("Les candidats ne sont pas tous affecte a une equipe")
      );
    }
    const { taskId, mobilizationId, assignmentId } = this.assignment;
    const assignment = { taskId, mobilizationId, assignmentId };
    const volunteers = this._candidates.map(
      ({ json }) => Candidate.toAssignment(json)
    );
    await this.repositories.assignments.assign({ assignment, volunteers });
    const { candidateFactory, assignments } = this.repositories;
    return ReadyToStart.init(candidateFactory, assignments);
  }
  async addCandidate() {
    if (!this.canFulfillMoreRemainingDemands) return Promise.resolve(this);
    const [nextFriend] = this.friendsAbleToFulfillNextDemand;
    const asCandidate = await this.toCandidate(nextFriend);
    const candidates = [...this._candidates, asCandidate];
    const assignedCandidates = this.assignCandidateAccordingToRemainingDemands(candidates);
    return new _AssignVolunteerFunnel(
      assignedCandidates,
      this.repositories,
      this.assignment
    );
  }
  assignCandidateAccordingToRemainingDemands(candidates) {
    const remainingDemands = this.remainingDemandsIfAssignThose(candidates);
    const assignedCandidates = candidates.map((candidate) => {
      if (isFulfillingDemand(candidate.json)) return candidate;
      const remainingAssignableTeams = candidate.assignableTeamsAccordingTo(remainingDemands);
      if (remainingAssignableTeams.length > 1) return candidate;
      const [lastRemainingTeam] = remainingAssignableTeams;
      return candidate.demandAs(lastRemainingTeam);
    });
    return assignedCandidates;
  }
  toCandidate(friend) {
    return this.repositories.candidateFactory.from(friend, this.assignment);
  }
  fulfillDemand({ volunteer, team }) {
    const hasTeamAllDemandsFulfilled = this.hasTeamAllDemandsFulfilled(team);
    if (hasTeamAllDemandsFulfilled) return this;
    const candidates = this._candidates.map(
      (candidate) => candidate.json.id === volunteer ? candidate.demandAs(team) : candidate
    );
    const assignedCandidates = this.assignCandidateAccordingToRemainingDemands(candidates);
    return new _AssignVolunteerFunnel(
      assignedCandidates,
      this.repositories,
      this.assignment
    );
  }
  hasTeamAllDemandsFulfilled(teamToAnalyse) {
    const teamRemainingDemands = this.remainingDemandsIfAssignThose(
      this._candidates
    ).filter(({ demand, team }) => team === teamToAnalyse && demand > 0);
    return teamRemainingDemands.length === 0;
  }
  revokeLastCandidate() {
    if (!this.canRevokeLastCandidate) return this;
    const candidates = this._candidates.slice(0, -1);
    return new _AssignVolunteerFunnel(
      candidates,
      this.repositories,
      this.assignment
    );
  }
  async countBasedCandidateSelection(increment) {
    if (!this.canChangeLastCandidate) return Promise.resolve(this);
    const currentFriend = this._candidates.at(-1);
    if (!currentFriend) return Promise.resolve(this);
    const friendsAssignableOnLastDemand = this.friendsAssignableOnLastDemand;
    const currentFriendIndex = friendsAssignableOnLastDemand.findIndex(
      ({ id }) => currentFriend.json.id === id
    );
    const totalAssignableFriends = friendsAssignableOnLastDemand.length;
    const nextIndex = (currentFriendIndex + increment) % totalAssignableFriends;
    const nextFriend = friendsAssignableOnLastDemand.at(nextIndex);
    if (!nextFriend) return Promise.resolve(this);
    const otherCandidatesThanTheLastOne = this.otherCandidatesThanTheLastOne;
    const asCandidate = await this.toCandidate(nextFriend);
    const candidates = [...otherCandidatesThanTheLastOne, asCandidate];
    const assignedCandidates = this.assignCandidateAccordingToRemainingDemands(candidates);
    return new _AssignVolunteerFunnel(
      assignedCandidates,
      this.repositories,
      this.assignment
    );
  }
  async previousCandidate() {
    return this.countBasedCandidateSelection(-1);
  }
  nextCandidate() {
    return this.countBasedCandidateSelection(1);
  }
};
export {
  AssignTaskToVolunteer,
  AssignVolunteerFunnel,
  AssignVolunteerToTask,
  AssignmentError,
  CONFIANCE,
  Candidate,
  CandidateFactory,
  HARD,
  ReadyToStart,
  TaskNotFoundError,
  VIEUX,
  countAssigneesInTeam,
  isTeamMember,
  isWithDetails
};
